import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_footer_color',
        label: 'Text color',
        type: 'color',
        default: theme.palette.primary.contrastText,
      },
      // {
      //   name: 'theme_component_footer_link_color',
      //   label: 'Link color',
      //   type: 'color',
      //   default: theme.palette.primary.contrastText,
      // },
      {
        name: 'theme_component_footer_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.primary.main,
      },
      // {
      //   name: 'theme_component_footer_background_image',
      //   label: 'Background image',
      //   type: 'text',
      //   startAdornment: 'url(https://',
      //   endAdornment: '/)',
      // },
      // {
      //   name: 'theme_component_footer_background_position',
      //   label: 'Background position',
      //   type: 'select',
      //   default: 'center',
      //   options: ['top', 'right', 'bottom', 'left', 'center'],
      // },
      // {
      //   name: 'theme_component_footer_background_size',
      //   label: 'Background size',
      //   type: 'select',
      //   default: 'cover',
      //   options: ['cover', 'contain', 'initial', 'inherit', 'auto'],
      // },
      // {
      //   name: 'theme_component_footer_background_repeat',
      //   label: 'Background repeat',
      //   type: 'select',
      //   default: 'no-repeat',
      //   options: ['no-repeat', 'repeat-y', 'repeat-x', 'repeat'],
      // },
    ],
    configuration: [
      // {
      //   name: 'component_footer_bar_made_by_disabled',
      //   label: 'Made-by Azerion mini footer disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      // {
      //   name: 'component_footer_bar_privacy_url_disabled',
      //   label: 'Privacy statement URL disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      {
        name: 'component_advertisement_show',
        label: 'Show Sticky Advertisement',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_footer_bar_azerion_footer',
        label: 'Azerion branded footer visible',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_footer_bar_privacy_url',
        label: 'Privacy statement URL',
        type: 'text',
        helperText: 'Only supply a URL if you want to link towards a different site.',
      },
      // {
      //   name: 'component_footer_bar_terms_url_disabled',
      //   label: 'Terms and agreements URL disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      {
        name: 'component_footer_bar_terms_url',
        label: 'Terms and agreements URL',
        type: 'text',
        helperText: 'Only supply a URL if you want to link towards a different site.',
      },
      // {
      //   name: 'component_footer_bar_cookies_url_disabled',
      //   label: 'Cookie statement URL disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      {
        name: 'component_footer_bar_cookies_url',
        label: 'Cookie statement URL',
        type: 'text',
        helperText: 'Only supply a URL if you want to link towards a different site.',
      },
      // {
      //   name: 'component_footer_bar_help_center_url_disabled',
      //   label: 'Help center URL disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      // {
      //   name: 'component_footer_bar_help_center_url',
      //   label: 'Help center URL',
      //   type: 'text',
      //   helperText: 'Only supply a URL if you want to link towards a different site.',
      // },
      // {
      //   name: 'component_footer_bar_contact_url_disabled',
      //   label: 'Contact URL disabled',
      //   type: 'checkbox',
      //   default: false,
      // },
      {
        name: 'component_footer_bar_contact_url',
        label: 'Contact URL',
        type: 'text',
        helperText: 'Only supply a URL if you want to link towards a different site.',
      },
      // {
      //   type: 'divider',
      // },
      // {
      //   type: 'header',
      //   label: 'Colophon',
      // },
      // {
      //   name: 'component_footer_bar_colophon_text',
      //   label: 'Colophon text',
      //   type: 'text',
      //   helperText: 'The small text at the bottom of the footer, e.g. the copyright text.',
      // },
      // {
      //   type: 'divider',
      // },
      // {
      //   type: 'header',
      //   label: 'Social links',
      // },
      // {
      //   name: 'component_footer_bar_twitch_url',
      //   label: 'Twitch URL',
      //   type: 'text',
      // },
      // {
      //   name: 'component_footer_bar_youtube_url',
      //   label: 'Youtube URL',
      //   type: 'text',
      // },
      // {
      //   name: 'component_footer_bar_instagram_url',
      //   label: 'Instagram URL',
      //   type: 'text',
      // },
      // {
      //   name: 'component_footer_bar_twitter_url',
      //   label: 'Twitter URL',
      //   type: 'text',
      // },
      // {
      //   name: 'component_footer_bar_facebook_url',
      //   label: 'Facebook URL',
      //   type: 'text',
      // },
      // {
      //   name: 'component_footer_bar_tiktok_url',
      //   label: 'TikTok URL',
      //   type: 'text',
      // },
    ],
  });
}