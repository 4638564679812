import type { Children } from '../../types';
import type { Variant } from '@mui/material/styles/createTypography';
import type { OverridableStringUnion } from '@mui/types';
import type { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';
import type { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useHighlightContext } from './index';

type MuiButtonVariants = OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
type MuiTypographyVariants = OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
export type HighlightEnabledVariantsProps = MuiTypographyVariants | MuiButtonVariants;

interface HighlightHandlerProps extends Children {
  forwardedRef: React.ForwardedRef<HTMLElement>;
  variant?: HighlightEnabledVariantsProps;
}

// Todo: Have a hard time setting the type for Component, as it can be Mui Typography or our own Link component.
export const useHighlightHandlers = (Component: any, props: HighlightHandlerProps) => {
  const { enabledVariants } = useHighlightContext();
  const { forwardedRef, variant, ...rest } = props;
  const theme = useTheme();
  let element: HTMLInputElement | HTMLAnchorElement | null = null;

  // Todo: Not sure how to resolve this missing current ts warning.
  // @ts-ignore
  if (forwardedRef && forwardedRef?.current) {
    // Todo: Not sure how to resolve this missing current ts warning.
    // @ts-ignore
    element = forwardedRef.current as HTMLElement;
  }

  if (element && variant && Array.isArray(enabledVariants)) {
    if (enabledVariants.includes(variant)) {
      element.style.outline = `2px dotted ${theme.palette.info.main}`;
      element.style.outlineOffset = '-2px';
      element.style.position = 'relative';

      if (!element.querySelector('.typographyBadge')) {
        const badge = document.createElement('div');
        badge.classList.add('typographyBadge');
        badge.style.position = 'absolute';
        badge.style.top = theme.spacing(-0.5);
        badge.style.right = theme.spacing(-0.5);
        badge.style.backgroundColor = theme.palette.info.main;
        badge.style.borderRadius = `${theme.shape.borderRadius}px`;
        badge.style.color = theme.palette.info.contrastText;
        badge.style.fontSize = '10px';
        badge.style.lineHeight = '10px';
        badge.style.padding = theme.spacing(0.5, 1);
        badge.style.boxShadow = theme.shadows[1];
        badge.style.textTransform = 'capitalize';
        badge.innerText = ['contained', 'text', 'outlined'].includes(variant) ? 'button' : variant;

        element.appendChild(badge);
      }
    } else {
      element.style.outline = 'unset';
      element.querySelector('.typographyBadge')?.remove();
    }
  }

  return <Component {...rest} variant={variant} ref={forwardedRef}/>
}
